/******************************************************************/
//------------------ ROTA PÁGINA NÃO ENCONTRADA ------------------//
/*****************************************************************/
import $ from 'jquery';


//Estilos
import imgPageNotFound from '../image/pageNotFound.png'

//Script


//Componentes


const NoPage = ()=>{
    document.body.id = "PageNotFound"
    return(
        <>
            <div className='pageNotFound'>                
                <img src={imgPageNotFound} alt="Página não encontrada" />
            </div>


        </>
    )
}

export default NoPage;


