import $ from 'jquery';

/************ CONTADOR PARA AS METAS **************/
//Verificar se esta no Index
/*setInterval(function(pagDemo){   
    //Data definida da demo - Contador de dias, horas, minutos e segundos
    var dataDemo = new Date('November 11, 2022, 00:00:00')
    var timeDemo = dataDemo.getTime()
    var dateAtual = new Date()
    var timeData = dateAtual.getTime()   
    
    var contTime = timeDemo - timeData
    
    var contDay = Math.floor(contTime / (1000 * 60 * 60 * 24));
    var contYour = Math.floor((contTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var contMinute = Math.floor((contTime % (1000 * 60 * 60)) / (1000 * 60));
    var contSecond = Math.floor((contTime % (1000 * 60)) / 1000);


    

    document.getElementsByClassName('cont')[0].innerHTML = contDay
    document.getElementsByClassName('cont')[1].innerHTML = contYour
    document.getElementsByClassName('cont')[2].innerHTML = contMinute
    document.getElementsByClassName('cont')[3].innerHTML = contSecond
     

    //Desbloquear a demo de acordo com o contador
    if(contDay == 0 && contYour == 0 && contMinute == 0){
        document.getElementsByClassName('cont')[0].innerHTML = 0
        document.getElementsByClassName('cont')[1].innerHTML = 0
        document.getElementsByClassName('cont')[2].innerHTML = 0
        document.getElementsByClassName('cont')[3].innerHTML = 0        
    }
})*/


/************ METAS PARA OS ITENS **************/
/*Metas
5 (tiara)
10 (meia)
50 (sapato)
100 (bracelete)
500 (jardineira)
1000 (blusa)*/


$(".c1").mouseover(function(){
    $("#c1").css({"display": "inline"});
});
$(".c1").mouseout(function(){
    $("#c1").css("display", "none");
});


$(".c2").mouseover(function(){
    $("#c2").css("display", "inline");
});
$(".c2").mouseout(function(){
    $("#c2").css("display", "none");
});


$(".c3").mouseover(function(){
    $("#c3").css({"display": "inline"});
});
$(".c3").mouseout(function(){
    $("#c3").css("display", "none");
});


$(".c4").mouseover(function(){
    $("#c4").css({"display": "inline"});
});
$(".c4").mouseout(function(){
    $("#c4").css("display", "none");
});


$(".c5").mouseover(function(){
    $("#c5").css({"display": "inline"});
});
$(".c5").mouseout(function(){
    $("#c5").css("display", "none");
});


$(".c6").mouseover(function(){
    $("#c6").css({"display": "inline", "z-index": "1"});
});
$(".c6").mouseout(function(){
    $("#c6").css("display", "none");
});


    