/**********************************************/
//------------------ FOOTER ------------------//
/*********************************************/

import { Link } from 'react-router-dom';

//Estilo
import './style.css'
import iconf from '../../image/facebook-redes.png'

//Script
import {ImgEnter, ImgLeave} from '../../js/script/ButtonsTrocar'


function Footer(){
    return(
        <footer className="footer">
            <div className="contato">            
            <Link to='/sobre'>Sobre</Link>
                <p>| @2021</p>
            </div>        
            <div className="redes-sociais">
                <a  href="https://www.facebook.com/The-Little-Universe-110753701438870">
                    <img id="face" onMouseEnter={()=>ImgEnter('#face', 'f')} onMouseLeave={()=>ImgLeave('#face', 'f')} src={iconf} alt="Facebook" />
                </a>          
            </div>
        
        </footer>      
    )
}

export default Footer;

