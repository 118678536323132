/****************************************************************/
//------------------ ROTA PÁGINA DEMONSTRAÇÃO ------------------//
/***************************************************************/


import {Outlet, Link} from 'react-router-dom'
import $ from 'jquery';
import React from "react";

//Estilos
import logo from '../logo.png';
import diretoraChibi from '../image/chibi-sensei.png'
import tutoria1 from '../image/notificTuto.png'
import tutoria2 from '../image/notificTutoA.png'

//Script
import {startDemo} from '../js/script/DemoEps.js'
import {btnNextTutorial, btnNotificEps} from '../js/script/ButtonsDemo.js'

//Componentes
import Footer from '../components/Footer';


const Demo = ()=>{
    return(
        <>
            
            <div className="box">
                <a href="/"><img className="logo" src={logo} alt="The little Universe"/></a>
                <div className="box-eps">
                    <div className="content-eps">
                
                        <div id="view-objective">
                            <div id="content-objective">
                                <div className="objectives" id="ob-1"></div>     
                            </div>                                        
                            <div id="btn-vo" onClick={()=>btnNotificEps()}>&#9674;</div>
                        </div>

                        <div id="startDemo" onClick={()=>startDemo()}>
                            <p>Jogar</p>
                        </div>

                        <div className="demo-text"></div>

                        <div className="pass-walk" mapa="???"></div>

                        <div className="caracter"></div>
                        
                        <div className="caracter-dialog">
                            <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">                    
                                <defs>                         
                                    <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">                            
                                        <stop id="stop1" offset="0%"></stop>                           
                                        <stop id="stop2" offset="100%"></stop>                        
                                    </linearGradient>  
                                </defs>      
                                <path fill="url(#sw-gradient)" d="M23.5,-28.7C30.5,-22.1,36.2,-14.8,39.3,-5.7C42.3,3.3,42.5,14,38.3,22.8C34,31.6,25.2,38.4,15.8,40.5C6.5,42.6,-3.6,39.9,-12.7,36.1C-21.8,32.2,-30.1,27.2,-35.2,19.7C-40.2,12.2,-42.2,2.2,-40.3,-6.8C-38.4,-15.9,-32.7,-24,-25.3,-30.5C-18,-37.1,-9,-42,-0.4,-41.6C8.3,-41.2,16.5,-35.3,23.5,-28.7Z" width="100%" height="100%" transform="translate(50 50)" stroke="url(#sw-gradient)"></path>              
                            </svg>     
                            <p className="text-dialog-pj"></p>               
                        </div>


                        <div className="select-option">
				            <div className="option"></div>
			            </div>

						<div id='endDemo'>
							<p>Obrigada por jogar!<br/>Espero que tenha se divertido</p>
							<div id="startDemo" onClick={()=>startDemo()}>
								<p>Jogar Novamente</p>
							</div>
						</div>
                        
                    </div>
                 
                </div> 

            </div> 

            <div id="content-tutorial">
                <div id="tutorial">
                    <img className="chibi-sensei" src={diretoraChibi} alt="Diretora Anastacia"/>                
                    <div className="tutorial">
                        <p>Olá! Me chamo Anastacia, e antes de inciar, tenho uma informação que pode ser útil quando ficar perdida(o) no decorrido da história.</p>
                        <img id="imgTutorial" src={tutoria1} alt="Botão de objetivo"/>
                        <img id="imgTutorial2" src={tutoria2} alt="Botão de objetivo"/>
                    
                        <div id="btnNextTut" onClick={()=>btnNextTutorial()}>&#8658;</div>    
                    </div>               

                </div>                                  
            </div>           

            <Footer></Footer>          
        </>
    )
}

export default Demo;
































































