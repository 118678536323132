import {BrowserRouter, Routes, Route} from 'react-router-dom';

//Estilos
import './css/App.css';
import './css/Demo.css';
import './css/WidescreenStyle.css';
import './css/bootstrap.css';

//Script


//Rotas
import Sobre from './routes/Sobre.routes';
import EmBreve from './routes/EmBreve.routes';
import Demo from './routes/Demo.routes';
import Layout from './routes/Layout.routes';
import NoPage from './routes/NoPage.routes';



function App() {
  return (
    <>   
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout/>}></Route>
          <Route index path='Sobre' element={<Sobre/>}></Route>
          <Route index path='Demo' element={<Demo/>}></Route>
          <Route index path='Login' element={<EmBreve/>}></Route>
          <Route index path='Pre-Registro' element={<EmBreve/>}></Route>
          <Route index path='*' element={<NoPage/>}></Route>
        </Routes>        
      </BrowserRouter>
    </>    
  );
}

export default App;
