/*******************************************/
//------------------ MENU ------------------//
/*******************************************/
import { Link } from 'react-router-dom';

//Estilo
import './style.css'

//Script
import '../../js/script/Time.js'


function Menu(){
    return(
        <div id="navbar">   
            <a className="inicio" href="/">Inicio</a>
            <a className="sobreTop" href="/sobre">Sobre</a> 

            <div className="time-content">
                <h4 id="time"></h4>
            </div> 

            <a className="login" href="/login">Login</a>
            <a className="cad" href="/pre-registro">Pré-Registro</a>
            <a id="navbar-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">≡</a> 
            <ul className="dropdown-menu" aria-labelledby="navbar-dropdown">
                <li><a href="/">Início</a></li>
                <li><a href="/sobre">Sobre</a></li>
                <li><a href="/login">Login</a></li>
                <li><a href="/pre-registro">Pré-Registro</a></li>
            </ul>          
      </div> 
    )
}

export default Menu;

