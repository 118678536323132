/*********************************************************************/
//------------------ ROTA PÁGINA SOBRE/INFORMAÇÕES ------------------//
/********************************************************************/

import {Outlet, Link} from 'react-router-dom'
import $ from 'jquery';
import React from "react";

//Estilos
import logo from '../logo.png';

//Script


//Componentes
import Footer from '../components/Footer';
import Menu from '../components/Menu';


//Abas de informações
let btnVO= 0
let textInfo
let mediaWindow = window.matchMedia('(max-width: 749px)')

const textAba = (divId)=>{
  if(divId === "#i0"){
    textInfo = "Varden Academic é uma academia escolar, internato, para alunos de alto padrão da sociedade, no qual uma vez por ano, promove um evento mundial para alunos que estão ingressando no ensino médio, esse evento se aplica a uma prova de admissão com direito a apenas uma bolsa integral. Suas instalações são refinadas, com atividades acadêmicas diversas para compor a disciplina e caráter do aluno."
    return textInfo

  }else if(divId === "#i1"){
    textInfo = "Uma garota de cidade pequena por acaso resolve fazer uma prova quase impossível de se passar e bastante concorrida, sem saber o que seria do seu futuro, através do incentivo e apoio da sua professora, que a conhecia desde pequena e a via com bastante potencial, além de seu pai, resolveu tentar, pois não teria nada a perder. Sem saber que apenas um pedaço de papel poderia causar uma grande reviravolta na sua vida, seria esse o destino que a aguarda durante tanto tempo?!"
    return textInfo

  }else if(divId === "#i2"){
    textInfo = "The Little Universe é um Otome game brasileiro baseado em animes, mangás shoujo e histórias de comédia romântica, no qual o(a) jogador(a) viverá a heroína, participando e interagindo com a história e os personagens presentes em sua volta, descobrindo uma vida completamente diferente da qual vivia."
    return textInfo

  }else if(divId === "#i3"){
    textInfo = "Somos uma equipe pequena com apenas duas pessoas, então pedimos a compreensão de vocês é qualquer erro ou bug, por favor, entre em contato através do nosso email para analisarmos e assim podermos corrigir, ficamos gratos pela ajuda e compreensão.\n" + "brduoakata@gmail.com"
    return textInfo
  }
}

const creatTextInfor = (textInfo, idInfo, bText)=>{    
  if(bText === true){ 
    if (window.matchMedia("(max-width: 749px)").matches) {
      $(idInfo + " h3").css({"top": "30px", "text-shadow":'1px 2px 6px rgb(0 0 0)'})
    }else{
      $(idInfo + " h3").css({"top": "260px", "text-shadow":'1px 2px 6px rgb(0 0 0)'})
    }
    $(idInfo + " p").css("display", "block").text(textInfo)   

  }else {
    $('#i0' + " p").removeAttr("style").text(textInfo)
    $('#i1' + " p").removeAttr("style").text(textInfo)
    $('#i2' + " p").removeAttr("style").text(textInfo)
    $('#i3' + " p").removeAttr("style").text(textInfo)  
    $('#i0' + " h3").removeAttr("style")
    $('#i1' + " h3").removeAttr("style")
    $('#i2' + " h3").removeAttr("style")
    $('#i3' + " h3").removeAttr("style") 
  }
}

const screenWindow = (idInfo, bAtivo)=>{
  if(bAtivo === true){
    // Tela menor 750
    if (window.matchMedia("(max-width: 749px)").matches) {
      $(idInfo).animate({height: "450px"})
    } else {
      $(idInfo).animate({width: "600px"})
    }
  }else{
    if (window.matchMedia("(max-width: 749px)").matches) {
      $("#i0").animate({height: "150px"})
      $("#i1").animate({height: "150px"})
      $("#i2").animate({height: "150px"})
      $("#i3").animate({height: "150px"})
    } else {
      $("#i0").animate({width: "200px"})
      $("#i1").animate({width: "200px"})
      $("#i2").animate({width: "200px"})
      $("#i3").animate({width: "200px"})
    }   
  }
}

const clickAba = (divId)=>{
  if(btnVO === 0){        
    screenWindow(divId, true)
               
    $(divId).css({"outline": "rgb(164, 154, 49) double 6px",
            "box-shadow": "0px 0px 26px 5px rgb(255 255 255)"
    })         
    $(divId).css("backgroundPosition", "center") 

    textAba(divId)    
    creatTextInfor(textAba(divId), divId, true)

    btnVO++


  }else if( btnVO === 1){        
    screenWindow(divId, false)
      
    $("#i0").css({"outline": "", "box-shadow": ""})
    $("#i1").css({"outline": "", "box-shadow": ""}) 
    $("#i2").css({"outline": "", "box-shadow": ""}) 
    $("#i3").css({"outline": "", "box-shadow": ""}) 
    $("#i0").css("backgroundPosition", "inherit")
    
    textInfo = " "
    creatTextInfor(textInfo, divId, false)
    btnVO--
  }     
}


const Sobre=()=>{
  
   return(
    <>
      <Menu></Menu>

      <div className="box">                
        <a href="/"><img className="logo" src={logo} alt="The little Universe"/></a>                
        <div className="content-info">
          <div className="sobre-top sobre">
            <div id="avisoLogRes">Otome game baseado em animes e mangás shoujos no qual a heroína passará a viver em um lugar totalmente oposto ao qual vivia, com garotos e garotas completamente diferentes do seu cotidiano considerado por ela como “normal”.</div> 
          </div>
            
          <div className="box-sobre">
            <div onClick={()=>clickAba('#i0')} id="i0" className="info ibord">
              <h3>Escola</h3>
              <p></p>
            </div>
            <div id="i1" onClick={()=>clickAba('#i1')}className="info">
              <h3>História</h3>
              <p></p>
            </div>
            <div id="i2" onClick={()=>clickAba('#i2')} className="info">
              <h3>O Jogo</h3>
              <p></p>
            </div>
            <div id="i3" onClick={()=>clickAba('#i3')} className="info ibord2">
              <h3>Equipe/Contato<br/>Requisitos</h3>
              <p></p>
            </div>
          </div>    
        </div>       
      </div>

      <Footer></Footer>     

    </>
   );
}

export default Sobre;