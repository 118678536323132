import $ from 'jquery';

//Import demo sceners
import demo3 from '../../image/scene/Demo3.png'
import demo4 from '../../image/scene/Demo4.png'
import demo5 from '../../image/scene/Demo5.png'
import demo6 from '../../image/scene/Demo6.png'
import demo7 from '../../image/scene/Demo7.png'
//-----------------------//

//INICIANDO A DEMO
export function startDemo(){
	$("#startDemo").css("display", "none");
	start();
}

//--------------------- Inicio da Demo ---------------------//

function start() {
	desenharCenario();
	narration();
	$("#endDemo").css("display","none");
}
  
  function desenharCenario() {
	$(".content-eps").css("background-color", "black");
  }
  
  function narration() {  

	$(".demo-text").css("display", "block");
	$(".objectives").css("display", "none");
	  
	let div = document.getElementsByClassName("demo-text")[0];
	let textos = ['...', 'Isto é o vento?', 'Estava sentindo uma leve brisa passando no ar, levando consigo um delicioso perfume de flores.', 'Parecia que estava em um campo florido...Ou na pracinha perto de casa.'];  
	$(".demo-text").addClass("dt1 borderGrad2");
	
	
	function escrever(str, done) {
	  let char = str.split('').reverse();
	  let typer = setInterval(function(){
		if (!char.length) {
		  clearInterval(typer);
		  return setTimeout(done, 500); // só para esperar um bocadinho        
		}
  
		let next = char.pop();
		div.innerHTML += next;
	  }, 75);
	}
  
	function rodape(conteudos, el) {
	  let atual = -1;
  
	  function prox() {
		if (atual < conteudos.length - 1) atual++;
  
		else atual = mudarClick();

		let str = conteudos[atual];
		escrever(str, function() {
		  div.innerHTML = '';
		  prox();
		});
	  }
	  prox();
	}
	rodape(textos);
  }
  
  function mudarClick() {
	$(".demo-text").css("display", "none"); 
	$(".demo-text").removeClass("dt1 borderGrad2"); 
	$(".demo-text").addClass("dt2 borderGrad3"); 

	let divNew = "<div class='eye-open' id='eo'></div>"
	$(".content-eps").append(divNew);
	$(".eye-open").css("display", "block");		
	$(".eye-open").hide().fadeIn(2500); 
	$(".eye-open").css('animation', 'forwards').css({'animation-name': 'blur', 'animation-duration': '8s'})

	setTimeout(function(){ 
		$(".eye-open").animate({"opacity": "0"})
		$(".content-eps").css("background-color", "rgb(255,255,255)")
		setTimeout(function(){ 					
			$(".eye-open").css("display", "none")
			$(".content-eps").hide().fadeIn(2500);
			$(".content-eps").css({"background-image": "url("+ demo3 +")",
								"background-size": "cover",
								"background-position": "top",
								"background-position-x": "inherit"});

			setTimeout(function(){
				$(".select-option").css("display", "block");
				$(".demo-text").css("display", "block"); 
				$(".demo-text").hide().fadeIn();
				$(".demo-text").text("Eu estava deitada na grama macia e reconfortante, o céu estava diante de mim, era perfeito! O sol brilhava e as nuvens pareciam se divertir naquela imensidão azul.");
				$(".option").animate({"opacity": 1}).text("Que lindo! Tão brilhante");
			},4000)						

		}, 800);		
		
	}, 8200);
	
	let counter = 0;	
	  
	$(".option").on("click", function(event) {
	  let passo = 0;
	  
		
	  counter++;
	  switch (counter) {
		case 1:
		  {
			$(".demo-text").css("display", "none"); 
			$(".content-eps").css({"background-image": "url("+ demo4 +")",
									"background-size": "800px 600px"});
			$(".option").text("Onde estou...?");
			break;
		  }
		case 2:
			{			
				$(".option").css("display", "none");
				$(".pass-walk").css("display", "block");
				$(".pass-walk").css("top", "305px").css("right", "100px");		  
				
				$(".pass-walk").on("click", function(event) {
					passo++;
					if(passo===1) {
						$(".content-eps").css({"background-image": "url("+ demo5 +")",
												"background-size": "800px 600px"});				
						$(".pass-walk").css("display", "none");
						$(".demo-text").css("display", "block"); 
						$(".option").css("display", "block");	
						$(".demo-text").hide().fadeIn(1000);					
						$(".demo-text").text("Ao longo do campo verde, avistei um pequeno lago e decidi seguir em sua direção.");
						$(".option").text("(Continua...)");	
					}			  
				});
				break;
			}
		case 3:
			{
				$("#btn-vo").css("text-shadow", "0px 0px 5px rgb(255, 0, 141)")
				$(".demo-text").hide().fadeIn(1000);
				$("#ob-1").text("Siga em direção ao lago")			
				$(".demo-text").text("Sentia como se alguém estivesse observando meus passos minuciosamente de longe...");
				$(".option").text("(Continua...)");	
				break;	
			}
		case 4:
			{
				$(".demo-text").css("display", "none");
				$(".option").css("display", "none");			
				$(".pass-walk").css({"display": "block",
									"top": "500px",
									"right": "100px"});			
				$(".pass-walk").attr("mapa", "Lago");
				$("[mapa]::after ").css("right", "4px")

				$(".pass-walk").on("click", function(event) {
					passo++;			  
					if(passo===1) {						
						$(".content-eps").css({"background-image": "url("+ demo6 +")",
												"background-size": "800px 600px"});
						$(".pass-walk").css("display", "none");
						$(".demo-text").css("display", "block").hide().fadeIn(1000);
						$(".demo-text").text("Ao me aproximar do lago ouvi alguém chamando meu nome.");
						$(".option").css("display", "block").text("(Continua...)");													  
					} 					  
				});			
				break;
			}
		case 5:
			{
				$(".content-eps").css({"background-image": "url("+ demo6 +")",
									"background-size": "800px 600px"});                  
				$(".demo-text").hide().fadeIn(1000).text("O sussurro suave e fino arrepiou ate meu ultimo fio de cabelo, trazendo a tona uma mistura de medo e insegurança. Porém, uma certa curiosidade me atingiu naquele momento. ");			
				$(".option").text("(Continua...)");	
				break;
			}
		case 6:
			{
				$("#btn-vo").css("text-shadow", "0px 0px 5px rgb(255, 0, 141)")

				divNew = "<div class='objectives' id='ob-2'></div>"
				$("#content-objective").append(divNew)

				$("#ob-1").css("text-decoration", "line-through")	
				$("#ob-2").text("Siga a voz")	

				$(".option").css("display", "none");
				$(".demo-text").css("display", "none");

				$(".pass-walk").removeAttr("style");
				$(".pass-walk").css({"display": "block",
									"top": "500px",
									"left": "100px"});			
				$(".pass-walk").attr("mapa", "???");
				$("[mapa]::after ").removeAttr("style")		  
				
				$(".pass-walk").on("click", function(event) {
					passo++;
					if(passo===1) {
						$(".content-eps").css({"background-image": "url("+ demo7 +")",
												"background-size": "800px 600px"});
						$(".pass-walk").css("display", "none");
						$(".option").css("display", "block");
						$(".demo-text").css("display", "block").hide().fadeIn(1000).text("Aquele sussurro tinha me levado a um campo aberto cheio de flores. Era lindo, parecia até cena de filme, então eu vi, uma silhueta extremamente familiar e a cada segundo que passava, a mesma ficava cada vez mais perto.");
						$(".option").text("(Continua...)");				 
					}			  
				});			  
				break;	
			}
		case 7:
			{	
				$("#btn-vo").css("text-shadow", "0px 0px 5px rgb(255, 0, 141)")
				$("#ob-2").css("text-decoration", "line-through");		
				$(".caracter").css("display", "block").hide().fadeIn(800);
				$(".demo-text").css("display", "none");
				$(".option").text("Não acredito, é você mesmo?");	
				break;			
			}
		case 8:
		 	{
				$(".caracter-dialog").css("display", "block").hide().fadeIn(1000)
				$(".text-dialog-pj").hide().fadeIn(1000).text("Quem mais seria my lady?! Eu disse que te encontraria!");			  				
				$(".option").text("(Continua...)");
				break;
		  	}
		case 9:
		  	{			
				$(".caracter").css("display", "none");
				$(".caracter-dialog").css("display","none");			
				$(".demo-text").css("display", "block").hide().fadeIn(1000).text("Ao terminar de falar, ele abriu seus braços em minha direção e com lágrimas nos olhos, eu corri o mais rápido que podia ao seu encontro, seus braços... Sentir sua fragrância empregnar minha roupa...");
				$(".option").text("...ter você novamente me faz tão fel-");
				break;
		  	}
		case 10:
			{
				$(".option").css("display", "none");
				$(".content-eps").css({'background-color': 'black', 'background-image': 'url()'})
				$(".content-eps").css({'animation': 'scale-up-center 0.4s linear 3s both',
										'-webkit-animation':'scale-up-center 0.4s linear 3s both'})	
				
				$(".demo-text").css("display", "block").removeClass("dt2 borderGrad3").addClass("dt1 borderGrad2").text('');
				

				let text = "Antes que eu terminasse de falar, ouvi um barulho e tudo ficou escuro, senti como se meu corpo tivesse batido em algo duro e uma voz irritante soou aos meus ouvidos."
				let demoText;
				var count = 0	
			  
				function escrever(){
					demoText = document.getElementsByClassName("demo-text")[0];
					window.setTimeout(function(){
						inserir(text[count])
					},100); //tempo de escrita
				}
				
				function inserir(lenght){
					demoText.innerHTML += lenght;
					count++;					
					if(count < text.length){
						window.setTimeout(function(){
							inserir(text[count])
							if(count >= 165){
								setTimeout(function(){
									$(".demo-text").css("display", "none") 
									$(".content-eps").css('background-color', 'rgb(72, 68, 71)')
									$("#endDemo").css("display","block");	
									$(".demo-text").text("")

								},1500);					
							}				
						},80);
					}
						
				}

				escrever();	
		 
				break;
			}

			default: 
			{
				$(".option").css("display", "none");
				$(".content-eps").css({'background-color': 'black', 'background-image': 'url()'})
				$(".content-eps").css({'animation': 'scale-up-center 0.4s linear 3s both',
										'-webkit-animation':'scale-up-center 0.4s linear 3s both'})	
				
				$(".demo-text").css({"display": "block",
									"color": "red" }).removeClass("dt2 borderGrad3").addClass("dt1 borderGrad2").text('Ocorreu um Erro inesperado, favor contactar o suporte: brduokata@gmail.com');

			}
		
	  	}
	});
  }

