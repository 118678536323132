import $ from 'jquery';

import iconf from '../../image/facebook-redes.png'
import iconf2 from '../../image/facebook-redes2.png'

/******************** INDEX - METAS ICONS ********************/
export function ImgMetaEnter(id){
    $(id).css('display', 'block')    
}
  
export function ImgMetaLeave(id){          
    $(id).css('display', 'none')
}

/******************** FOOTER - REDES SOCIAS ********************/

export function ImgEnter(id, tipo){
    if(tipo === 'f'){
        $(id).prop('src', iconf2)
    }
}
  
export function ImgLeave(id, tipo){
    if(tipo === 'f'){        
        $(id).prop('src', iconf)
    }    
}



/******************** HEAD - USUÁRIO ********************/
/*unction sairImgOver(x) {
    x.src = "./image/sair2.png";              
}f
function sairImgOut(x) {
    x.src = "./image/sair.png";            
}*/



/******************** MENU - USUÁRIO ********************/
/*function estatisticaImgOver(x) {
    x.src = "./image/estatisticaA-icon.png";              
}
function estatisticaImgOut(x) {
    x.src = "./image/estatisticaF-icon.png";            
}

function celularImgOver(x) {
    x.src = "./image/celular-icon2.png";              
}
function celularImgOut(x) {
    x.src = "./image/celular-icon.png";            
}*/



