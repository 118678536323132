import $ from 'jquery';

let btnVO = 0
let btnNotificElement = document.getElementById("btn-vo")
let btnTutorial = 0


//Botão de next do tutorial
export function btnNextTutorial(){
    if(btnTutorial === 0){
        $(".tutorial p").text("Durante o decorrer do capítulo haverá um botão onde estarão seus objetivos.")
        $("#imgTutorial").css("display", "block")
        btnTutorial++

    }else if(btnTutorial === 1){ 
        $(".tutorial p").text("Quando tiver um novo objetivo ou quando tiver concluído um objetivo, o icon mudará de cor.") 
        $("#imgTutorial2").css("display", "block")
        btnTutorial++

    }else if(btnTutorial === 2){
        $(".tutorial p").text("Lembre-se que suas escolhas influênciarão no decorrer da história.").append("<br/> Espero que gostem, tenham uma boa experiência. <br/> Até breve.")         
        $("#imgTutorial").css("display", "none")
        $("#imgTutorial2").css("display", "none")
        btnTutorial++

    }else{
        $("#content-tutorial").css("display", "none")        
        btnTutorial = 0
    }
}


//Botão de notificação do EpsDemo
export function btnNotificEps(){
    if(btnVO === 0){
        $("#view-objective").animate({width: "100%", borderBottomRightRadius: "0px"})
        $("#btn-vo").removeAttr("style")
        $(".objectives").css("display", "block").hide().fadeIn(1000)
        $("#btn-vo").addClass("shadowActive")    
             
        btnVO++
    }else if( btnVO === 1){
        $("#view-objective").animate({width: "50px", borderBottomRightRadius: "15px"})
        $(".objectives").css("display","none")
        $("#btn-vo").removeClass("shadowActive")    
        btnVO--
    }
}




