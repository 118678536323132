//Informação do horário normal
setInterval(function(){
    var data = new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'}); // Sao_Paulo = Brazilin
    /*var dia = data.slice(0,2)
    var mes = data.slice(3,5)
    var ano = data.slice(6,10)
    var hora = data.slice(11,13)
    var minutos = data.slice(14,16)
    var segundos = data.slice(17,19)*/
   //var fuso = data.getTimezoneOffset() //Resulta em -180m = -3h GMT Brasília
       

    //Definir formatação para horario do servidor
    var horaWord = data.split(' ')
    var horas = horaWord[1]
    var time = document.getElementById('time')

    if(time){
        time.innerHTML = horas //Time servidor
    }
    
    

    //Data definida da demo - Contador de dias, horas, minutos e segundos
    /*var dataDemo = new Date('November 10, 2022, 00:00:00')
    var timeDemo = dataDemo.getTime()
    var dateAtual = new Date()
    var timeData = dateAtual.getTime()   
    
    var contTime = timeDemo - timeData
    
    var contDay = Math.floor(contTime / (1000 * 60 * 60 * 24));
    var contYour = Math.floor((contTime % (1000 * 60 * 60 * 24)) / (10006060));
    var contMinute = Math.floor((contTime % (1000 * 60 * 60)) / (100060));
    var contSecond = Math.floor((contTime % (1000 * 60)) / 1000);

    document.getElementsByClassName('cont')[0].innerHTML = contDay
    document.getElementsByClassName('cont')[1].innerHTML = contYour
    document.getElementsByClassName('cont')[2].innerHTML = contMinute
    document.getElementsByClassName('cont')[3].innerHTML = contSecond
     
   // if(dia == 29 && mes == 5 && hora >= 22 && minutos >= 0 ){}else{}*/

})


