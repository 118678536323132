/*********************************************************/
//------------------ ROTA PÁGINA INDEX ------------------//
/********************************************************/

import {Outlet, Link} from 'react-router-dom'

//Estilos
import logo from '../logo.png';
import iconf from '../image/facebook-redes.png'
import carta from '../image/carta.png'
import moldura from '../image/moldura-carossel.png'
import roupaChapeu from '../image/IconAcessCabeca.png' 
import roupaAcess from '../image/IconAcessorio.png' 
import roupaSapato from '../image/IconSapato.png' 
import roupaCima from '../image/IconPCima.png'
import roupaBaixo from '../image/IconPBaixo.png' 
import roupaMeia from '../image/IconMeia.png' 

//Script
import metaScript from '../js/script/Meta.js' 
import {ImgMetaEnter, ImgMetaLeave } from '../js/script/ButtonsTrocar.js'

//Componentes
import Menu from '../components/Menu';
import Footer from '../components/Footer';


const Layout = ()=>{     
    return(
        <>
            <Menu></Menu>

            <div className="box">
                <a href="/">
                    <img className="logo" src={logo} alt="The little Universe"/>
                </a>     
                <div className="sobre-top">
                    <div id="avisoLogRes"><h3>Uma História cheia de animação e intrigas, na qual o(a) jogador(a) a cada capítulo conhecerá segredos da nossa heroína e dos personagens que a rodeia. </h3></div> 
                </div>

                <div className="dBtnTop">
                    <p >Experimente a demo!</p>
                    <a href="/demo">
                        <input type="button" value="DEMO" id="btnDemo"/>
                    </a> 
                    <a href="/pre-registro">
                        <input type="button" value="PRÉ-CADASTRO" id="btnRegistro"/>
                    </a>             
                </div>
            </div>  


            <section className="box gradient-box bg-light bg-gradient borderGrad">
                <div id="avisoQuadro">Contando os dias para a data de Pré-registro</div>
                <div id="timeQuadro">
                    <div className="contador con1">
                        Dias
                        <div className="cont">--</div>
                    </div>
                    <div className="contador">
                        Horas
                        <div className="cont">--</div>
                    </div>            
                    <div className="contador">
                        Minutos
                        <div className="cont">--</div>
                    </div>
                    <div className="contador">
                        Segundos
                        <div className="cont">--</div>
                    </div>
                    
                </div>

                <div className='marcador'>
                    <p className="metaItens" id="m-c1">1000</p>
                    <div className='check c1' onMouseEnter={()=>ImgMetaEnter('#c1')} onMouseLeave={()=>ImgMetaLeave('#c1')} ></div> 
                    <img id="c1" src={roupaCima} alt="Roupa"/>  

                    <p className="metaItens" id="m-c2">500</p>
                    <div className='check c2'onMouseEnter={()=>ImgMetaEnter('#c2')} onMouseLeave={()=>ImgMetaLeave('#c2')} ></div>
                    <img id="c2" src={roupaBaixo} alt="Roupa"/>
                            
                    <p className="metaItens" id="m-c3">100</p>
                    <div className='check c3' onMouseEnter={()=>ImgMetaEnter('#c3')} onMouseLeave={()=>ImgMetaLeave('#c3')} ></div>
                    <img id="c3" src={roupaAcess} alt="Acessório"/>

                    <p className="metaItens" id="m-c4">50</p>
                    <div className='check c4' onMouseEnter={()=>ImgMetaEnter('#c4')} onMouseLeave={()=>ImgMetaLeave('#c4')} ></div>
                    <img id="c4" src={roupaSapato} alt="Sapato"/>

                    <p className="metaItens" id="m-c5">10</p>
                    <div className='check c5'onMouseEnter={()=>ImgMetaEnter('#c5')} onMouseLeave={()=>ImgMetaLeave('#c5')} ></div>
                    <img id="c5" src={roupaMeia} alt="Meia"/>  

                    <p className="metaItens" id="m-c6">5</p>
                    <div className='check c6' onMouseEnter={()=>ImgMetaEnter('#c6')} onMouseLeave={()=>ImgMetaLeave('#c6')} ></div>
                    <img id="c6" src={roupaChapeu} alt="Acessório de Cabeça"/>  
                    
                        
                    {/*<div className="progress">
                        <div id="progress" className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>*/}

                    <div className="progress progress-bar-vertical">
                        <div id="progress" className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </section>



            <section id="personagens" className="box gradient-box bg-gradient">
                <div id="carouselPersonagens" className="carousel slide carousel-fade" data-bs-ride="carousel">               
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselPersonagens" data-bs-slide-to="0" className="active" aria-label="Slide 1" aria-current="true"></button>
                        <button type="button" data-bs-target="#carouselPersonagens" data-bs-slide-to="1" aria-label="Slide 2" className=""></button>
                        <button type="button" data-bs-target="#carouselPersonagens" data-bs-slide-to="2" aria-label="Slide 3" className=""></button>
                    </div>

                    <div className="carousel-inner content-pj"> 
                        <div className="carousel-item active" data-bs-interval="10000">
                            <img src={moldura} className="d-block personagem" alt="Sebastian"/>
                            <div className="carousel-caption d-md-block info-pj">
                                <h5>???</h5>
                                <p>???</p>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={moldura} className="d-block personagem" alt="Lucius"/>
                            <div className="carousel-caption d-md-block info-pj">
                                <h5>???</h5>
                                <p>???</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={moldura} className="d-block personagem" alt="Dean"/>
                            <div className="carousel-caption d-md-block info-pj">
                                <h5>???</h5>
                                <p>???</p>  
                            </div>
                        </div>                        
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselPersonagens" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselPersonagens" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>               
            </section>


            <section className="box bg-light bg-gradient borderGrad">
                <div className="carta">
                    <h3> Jogo de romance e comédia, onde suas escolhas influenciarão na sua história</h3>
                    <p>Seja uma adolescente que entrará em um mundo e realidade completamente diferente da qual vivia!<br/>Será capaz de sobreviver nessa nova realidade?!</p>
                    <img src={carta} alt="Carta de Convite"/>
                    <p id="text-carta"><i>Cara Jogadora ! <br/>
                        Temos o imenso prazer de informar que a senhorita 
                        acaba de conseguir uma bolsa integral para nossa escola.
                        Em breve você podera conhecer nossas instalações e 
                        apoderá aproveitear o melhor que a nossa escola tem a
                        oferecer. <br/>                
                        Carimbado e assinado pela diretora Anastácia Woffeman</i> 
                    </p>        
                </div>
            </section>

            <Footer></Footer>
        </>
    )
}














export default Layout;