/*********************************************************************/
//------------------ ROTA PÁGINA DE AVISO NOVIDADE ------------------//
/********************************************************************/


//Estilos
import iconf from '../image/facebook-redes.png'
import diretoraChibi from '../image/chibi-sensei.png'

//Script
import {ImgEnter, ImgLeave} from '../js/script/ButtonsTrocar.js'
import Footer from '../components/Footer'

//Componentes 
import Menu from '../components/Menu'


const EmBreve = ()=>{
    return(        
        <>            
            <Menu></Menu>
            <div className="avisoLoginRegisto">
                <div className="content-avisoLR">
                    <div className="avisoLR">
                        <h2>Pré-Cadastro e Login em Breve!</h2>
                        <p>Nós sigam nas nossas redes sociais para mais informações, obrigada.</p>                        
                        
                        <div className="redes-sociais">
                            <a href="https://www.facebook.com/The-Little-Universe-110753701438870" className="RS-A">
                                <img id="face" onMouseEnter={()=>ImgEnter('#face', 'f')} onMouseLeave={()=>ImgLeave('#face', 'f')} src={iconf} alt="Facebook" />
                                <p>acebook</p>
                            </a>            
                        </div>

                    </div>
                    <img className="avisoChibi" src={diretoraChibi} alt="Diretora Anastacia"/>                   

                </div>
            </div>


           
        </>
    )
}

export default EmBreve;



